<template>
  <div class="container">
    <div class="header flex-between-start">
      <div class="handle">
        <div class="title">活动统计</div>
        <el-button @click="handleExport" v-permission="['活动统计导出']">导出</el-button>
      </div>
      <div class="search">
        <el-select v-model="status" placeholder="请选择状态" clearable>
          <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-input v-model="sponsor" type="text" placeholder="请输入主办方名称" clearable></el-input>
        <el-input v-model="couponKey" type="text" placeholder="请输入活动名称 | 活动号" clearable></el-input>
        <el-button @click="pageEmit(1)">搜索</el-button>
      </div>
    </div>

    <!-- 列表 -->
    <el-table :data="tableData.items" v-loading="loading" height="700">
      <el-table-column prop="up_coupon_id" label="活动号" width="160"></el-table-column>
      <el-table-column prop="name" label="优惠券"></el-table-column>
      <el-table-column label="状态" width="100">
        <template #default="scope">
          <el-tag size="small" :type="enums.couponStatus[scope.row.status]?.status">{{
            enums.couponStatus[scope.row.status]?.label
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="" label="活动时间" width="180">
        <template #default="scope">
          <div class="flex-column-align">
            <p>{{ scope.row.start_time }} -</p>
            <p>{{ scope.row.end_time }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="sponsor_name" label="主办方名称"></el-table-column>
      <el-table-column prop="grant_nums" label="优惠券总数量"></el-table-column>
      <el-table-column prop="remaining_nums" label="剩余数量"></el-table-column>
      <el-table-column prop="" label="发放数量">
        <template #default="scope">
          {{ Number(scope.row.grant_nums) - Number(scope.row.remaining_nums) }}
        </template>
      </el-table-column>
      <el-table-column prop="coupon_used_total" label="核销数量"></el-table-column>
      <el-table-column prop="coupon_expire_total" label="已失效数量"></el-table-column>
      <el-table-column prop="" label="核销率" width="90">
        <template #default="scope"> {{ handleCount(scope.row) }}% </template>
      </el-table-column>
      <el-table-column prop="member_count" label="参与活动用户数" width="100"></el-table-column>
    </el-table>
    <!-- 分页 -->
    <page
      @page-event="pageEmit"
      :current-page="pageParam.currentPage"
      :total="tableData.total"
      :page-size="tableData.count"
    ></page>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import commonHandle from '@/hooks/useCommon'
import statisticsModel from '@/model/statistics'
import enums from '@/lin/util/enum'
import util from '@/lin/util/util'
import { ElMessage } from 'element-plus/lib/components'

export default {
  setup() {
    const statusList = ref([])
    const status = ref()
    const sponsor = ref()
    const couponKey = ref()

    const getData = async pageQuery => {
      if (couponKey.value && !util.checkLength(couponKey.value, 2)) return ElMessage.warning('活动名称或活动号至少输入2个字符')

      loading.value = true
      const res = await statisticsModel.getCouponStatistic({
        page: pageQuery?.page || pageParam.currentPage - 1,
        count: pageQuery?.count || pageParam.count,
        status: status.value || null,
        sponsor: sponsor.value || null,
        coupon_key: couponKey.value || null,
      })
      loading.value = false
      tableData.value = res
      return res
    }

    // 导出
    const handleExport = async () => {
      const res = await statisticsModel.couponStatisticExport({
        status: status.value || null,
        sponsor: sponsor.value || null,
        coupon_key: couponKey.value || null,
      })
      console.log(res)
      let list = []
      const tHeader = [
        '活动号',
        '优惠券',
        '状态',
        '活动时间',
        '主办方名称',
        '优惠券数量',
        '剩余数量',
        '参与活动用户数量',
        '优惠券发放数量',
        '优惠券核销数量',
        '核销率',
      ]
      const filterVal = [
        'up_coupon_id',
        'name',
        'status_name',
        'time',
        'sponsor_name',
        'grant_nums',
        'remaining_nums',
        'member_count',
        'send_num',
        'coupon_used_total',
        'used_rate',
      ]
      list = res.map(item => {
        item.coupon_used_total = Number(item.coupon_used_total)
        item.member_count = Number(item.member_count)
        return {
          ...item,
          status_name: enums.couponStatus[item.status].label,
          time: `${item.start_time} - ${item.end_time}`,
          send_num: Number(item.grant_nums) - Number(item.remaining_nums),
          used_rate: `${handleCount(item)}%`,
        }
      })
      const data = list.map(v => filterVal.map(j => v[j]))
      util.exportExcel(tHeader, data, '优惠券统计数据')
    }

    // 计算核销率
    const handleCount = row => {
      const sendCount = Number(row.grant_nums) - Number(row.remaining_nums)
      return sendCount == 0 ? 0 : ((Number(row.coupon_used_total) * 100) / sendCount).toFixed(2)
    }

    const { tableData, loading, pageParam, pageEmit } = commonHandle(getData)

    onMounted(() => {
      console.log(enums.couponStatus)
      statusList.value = enums.couponStatus.filter(item => item.value !== 0)
      console.log(statusList.value)
      getData()
    })

    return {
      statusList,
      status,
      sponsor,
      couponKey,
      tableData,
      loading,
      pageParam,
      pageEmit,
      handleExport,
      handleCount,
      enums,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/realize/custom';
</style>
